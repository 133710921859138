import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const MobileSubMenuMenuWrapper = styled.div`
  .mobile-submenu-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .icon {
      .iconOpen {
        svg {
          color: #886638;
          margin-left: 24px;
          width: 9px;
        }
      }
      .iconClose {
        svg {
          color: #886638;
          margin-left: 24px;
          width: 12px;
        }
      }
    }
  }

  .mobile-submenu {
    a {
      font-size: 14px;
      color: #886638;
      padding-top: 4px;
      padding-bottom: 8px;
    }
  }
`
