import Fade from "react-reveal/Fade"
import React, { useRef, useState } from "react"
import { Icon } from "react-icons-kit"
import { Link, graphql, useStaticQuery } from "gatsby"
import { menu } from "react-icons-kit/feather/menu"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { x } from "react-icons-kit/feather/x"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Logo from "../../components/UIElements/Logo"
import LogoIcon from "../../common/assets/image/elegant/logo.svg"
import ScrollSpyMenu from "../../components/ScrollSpyMenu"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import NavbarWrapper, {
  ButtonsContainer,
  MenuArea,
  MobileMenu,
} from "./navbar.style"

const Navbar = ({ home }) => {
  const data = useStaticQuery(graphql`
    query {
      elegantJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
          }
        }
      }
    }
  `)
  const { navMenu } = data.elegantJson.navbar

  const [state, setState] = useState({
    search: "",
    searchToggle: false,
    mobileMenu: false,
  })

  const searchRef = useRef(null)
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  )

  const toggleHandler = type => {
    if (type === "search") {
      setState({
        ...state,
        search: "",
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      })
    }

    if (type === "menu") {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      })
    }
  }

  const trackButtonEvent = () => {
    trackCustomEvent({
      category: "Button",
      action: "Book Now",
      label: "Salon IQ",
    })
  }

  return (
    <NavbarWrapper className="navbar" home={home}>
      <Container mobileGutter fullWidth>
        <Logo
          className="logo"
          href="/"
          logoSrc={LogoIcon}
          title="Elegant Beauty Clinique"
        />
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? "active" : ""}>
          <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
        </MenuArea>
        <ButtonsContainer>
          <Link to="/about/gift-voucher" className="gift-voucher">
            Gift Vouchers
          </Link>
          <a
            href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              className="trail"
              title="Book Now"
              onClick={trackButtonEvent}
            />
          </a>
          <Button
            ariaLabel="mobile-icon"
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler("menu")}
          />
        </ButtonsContainer>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? "active" : ""}`}>
        <Container>
          <ScrollSpyMenu
            className="menu"
            menuItems={navMenu}
            offset={-84}
            mobileMenu={state.mobileMenu}
            currentClassName="active"
          />
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  )
}

export default Navbar

// import React from "react"

// const Navbar = () => {
//   return <h1>Navbar</h1>
// }

// export default Navbar
