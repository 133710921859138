import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const NavbarWrapper = styled.nav`
  width: 100%;
  padding: 16px 0 16px;
  border-top: 1px solid #eaeaea;
  border-bottom: ${props => (props.home ? "none" : "1px solid #EAEAEA")};
  background-color: #fbfbf9;
  /* position: fixed; */
  z-index: 9999;
  transition: all 0.1s ease;

  > div.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;
    @media only screen and (max-width: 1600px) {
      padding: 0 40px;
    }
    @media only screen and (max-width: 1366px) {
      padding: 0 10px 0 20px;
    }
    .logo {
      img {
        width: 128px;
        height: auto;
        @media only screen and (max-width: 600px) {
          width: 110px;
        }
      }
    }
  }
  .submenu_wrapper {
    position: absolute;
    left: 0;
    font-size: 14px;
    color: ${themeGet("colors.textColor")};
    background-color: white;
    width: 100%;
    max-height: 430px;
    top: 75px;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    padding: 0px 30px 30px 30px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s linear;
    a {
      padding: 0px;
      margin: 20px 120px 0 0;
      transition: all 0.1s ease;
      @media only screen and (max-width: 1495px) {
        max-width: 250px;
      }
      @media only screen and (max-width: 1240px) {
        margin: 20px 70px 0 0;
      }
    }
    a:hover {
      color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
    }
  }

  .submenu_content {
    max-height: 390px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
    width: 80%;
    margin: 0 auto;
    align-content: center;
  }

  ul {
    li {
      &:hover .submenu_wrapper {
        display: block;
        visibility: visible;
        opacity: 1;
      }
      a {
        color: ${themeGet("colors.textColor", "#333333")};
        font-size: 15px;
        font-weight: 300;
        padding-bottom: 40px;
      }
      &.is-current {
        a {
          color: ${themeGet("colors.primary", "#2563FF")};
        }
      }
    }
  }
`

export const MenuArea = styled.div`
  display: flex;
  align-items: center;
  .menu {
    display: flex;
    align-items: center;
    margin-right: 32px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
    @media only screen and (max-width: 1366px) {
      margin-right: 13px;
    }
    @media only screen and (max-width: 1060px) {
      display: none;
    }
    li {
      margin: 0 19px;
      @media only screen and (max-width: 1366px) {
        margin: 0 17px;
      }

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.active {
    .menu {
      opacity: 0;
      visibility: hidden;
    }
    .search {
      form {
        opacity: 1;
        visibility: visible;
      }
      input {
        width: 320px;
        @media only screen and (min-width: 1367px) {
          width: 350px;
        }
        @media only screen and (max-width: 480px) {
          width: calc(100vw - 122px);
        }
      }
    }
  }
  .reusecore__button {
    color: ${themeGet("colors.white", "#fff")};
    background-color: ${themeGet("colors.secondaryBotton", "#886638")};
    border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
    border-radius: 24px;
    font-weight: 500;
    padding: 0 24px 0 24px;
    text-transform: inherit;
    font-size: 15px;
    min-height: 42px;
    min-width: 42px;
    @media only screen and (max-width: 600px) {
      padding: 0 16px 0 16px;
      min-height: 32px;
      min-width: 32px;
    }
    &:hover {
      background-color: #fbfbf9;
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      color: ${themeGet("colors.secondaryBotton", "#886638")};
    }

    &.text {
      padding: 0;
      margin-right: 28px;
      .btn-icon {
        svg {
          width: 22px;
          height: auto;
          stroke: ${themeGet("colors.menu", "0D233E")};
          @media only screen and (max-width: 991px) {
            width: 24px;
          }
        }
      }
      @media only screen and (max-width: 1366px) {
        margin-right: 28px;
      }
      @media only screen and (max-width: 991px) {
        margin-right: 28px;
      }
    }
  }
`

export const ButtonsContainer = styled.div`
	.gift-voucher {
		font-size: 15px;
		color:${themeGet("colors.secondaryBotton", "#886638")};
		font-weight: 600;
		text-decoration: underline;
		margin-right: 24px;
		transition: all 0.3s ease;
		letter-spacing: 0.3px;
		@media only screen and (max-width: 1220px) {
			margin-right: 12px;
		}
		@media only screen and (max-width: 568px) {
			display: none;
		}
	}

.reusecore__button {
    color: ${themeGet("colors.white", "#fff")};
    background-color: ${themeGet("colors.secondaryBotton", "#886638")};
    border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
    border-radius: 24px;
    font-weight: 500;
    padding: 0 24px 0 24px;
    text-transform: inherit;
    font-size: 15px;
    min-height: 42px;
    min-width: 42px;
    @media only screen and (max-width: 600px) {
      padding: 0 16px 0 16px;
      min-height: 32px;
      min-width: 32px;
    }
    &:hover {
      background-color: #fbfbf9;
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      color: ${themeGet("colors.secondaryBotton", "#886638")};
    }

		&.trail {
      margin-right: 0px;
    }

		&.menubar {
      display: none;
      @media only screen and (max-width: 1060px) {
        color: ${themeGet("colors.textColor", "#333333")};
        display: inline-flex;
        padding: 0;
        justify-content: flex-end;
        min-width: 35px;
        background-color: #fbfbf9;
        border-radius: 0px;
        border: none;
        &:hover {
          background-color: #fbfbf9;
        }
        svg {
          width: 40px;
          height: auto;
        }
      }
    }
`

export const MobileMenu = styled.div`
  display: none;
  @media only screen and (max-width: 1060px) {
    display: flex;
    width: 100%;
    height: calc(100vh - 70px);
    padding: 27px 0 40px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 73px;
    overflow: auto;
    flex-direction: column;
    background-color: ${themeGet("colors.white", "#ffffff")};
    &.active {
      opacity: 1;
      visibility: visible;
      box-shadow: 0 3px 12px
        ${themeGet("colors.shadow", "rgba(38, 78, 118, 0.1)")};
    }
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    ul {
      padding-bottom: 20px;
      li {
        a {
          color: ${themeGet("colors.textColor", "#333333")};
          display: block;
          padding: 13px 0;
          border-radius: 5px;
        }
        &:hover {
          a {
            background-color: ${themeGet("colors.primary")};
          }
        }
      }
    }
  }
`

export default NavbarWrapper
