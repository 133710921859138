import FooterWrapper, {
  LinksListItem,
  List,
  ListItem,
  SocialList,
} from "./footer.style"
import { Link, graphql, useStaticQuery } from "gatsby"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Cqc from "../../common/assets/image/elegant/cqc.png"
import Fade from "react-reveal/Fade"
import Heading from "../../components/Heading"
import Hydrafacial from "../../common/assets/image/elegant/hydrafacial.png"
import { Icon } from "react-icons-kit"
import Image from "../../components/Image"
import Profhilo from "../../common/assets/image/elegant/profhilo.png"
import PropTypes from "prop-types"
import React from "react"
import SaveFace from "../../common/assets/image/elegant/Save-Face.png"
import Seventyhyal from "../../common/assets/image/elegant/seventyhyal.png"
import Teosyal from "../../common/assets/image/elegant/teosyal.png"
import Text from "../../components/Text"
import ZoSkin from "../../common/assets/image/elegant/zoSkin.png"
import { facebook } from "react-icons-kit/fa/facebook"
import { instagram } from "react-icons-kit/fa/instagram"

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  textStyle,
  copyrightMenu,
  copyright,
  LinksStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      elegantJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            phone
            callNumber
            icon {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <Container width="1340px">
        <Fade up delay={100}>
          <Box className="row" {...row}>
            <Box {...colOne}>
              {Data.elegantJson.menuWidget.map(widget => (
                <Box className="col" {...col} key={widget.id}>
                  <Heading content={widget.title} {...titleStyle} />
                  <List>
                    {widget.menuItems.map(item => {
                      return (
                        <ListItem key={`list__item-${item.id}`}>
                          <Image
                            style={{ width: "15px", height: "19px" }}
                            src={item.icon.publicURL}
                            alt=""
                            className="ListIcon"
                          />
                          {item.text === null ? (
                            <a
                              href={`tel:${item.callNumber}`}
                              className="ListItem"
                            >
                              {item.phone}
                            </a>
                          ) : (
                            <p className="ListItem">{item.text}</p>
                          )}
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>
              ))}
            </Box>
            <Box className="socialInfos">
              <div>
                <div className="social">
                  <Heading content={"Follow us:"} {...titleStyle} />
                  <SocialList>
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/elegantbeautyclinic"
                        aria-label="social share link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon icon={facebook} />
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        href="https://www.instagram.com/elegantclinicuk/"
                        aria-label="social share link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon icon={instagram} />
                      </a>
                    </li>
                  </SocialList>
                </div>
                <div className="policies">
                  <Heading content={"Policies:"} {...LinksStyle} />
                  <List>
                    <LinksListItem>
                      <Link to="/cookie-policy/">
                        <p>Cookie Policy</p>
                      </Link>
                      <Link to="/privacy-policy/">
                        <p>Privacy Policy</p>
                      </Link>
                      <Link to="/terms-and-conditions/">
                        <p>Terms & Conditions</p>
                      </Link>
                    </LinksListItem>
                  </List>
                </div>
              </div>
              <div className="logos">
                <div className="cqc">
                  <a
                    href="https://www.cqc.org.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Cqc} alt="Cqc logo" />
                  </a>
                </div>
                <div className="save-face">
                  <a
                    href="https://www.saveface.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={SaveFace} alt="SaveFace logo" />
                  </a>
                </div>
                <div className="hydrafacial">
                  <a
                    href="https://www.hydrafacial.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Hydrafacial} alt="Hydrafacial logo" />
                  </a>
                </div>
                <div className="profhilo">
                  <a
                    href="https://www.profhilo.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Profhilo} alt="profhilo logo" />
                  </a>
                </div>
                <div className="teosyal">
                  <a
                    href="https://www.profhilo.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Teosyal} alt="teosyal logo" />
                  </a>
                </div>
                <div className="seventyhyal">
                  <a
                    href="https://www.seventyhyal.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Seventyhyal} alt="seventyhyal logo" />
                  </a>
                </div>
                <div className="zoSkin">
                  <a
                    href="https://www.seventyhyal.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ZoSkin} alt="zoSkin logo" />
                  </a>
                </div>
                {/* <div className="finance">
                  <a
                    href="https://payl8r.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Payl8r} alt="payl8r logo" />
                  </a>
                </div> */}
              </div>
            </Box>
            <Box {...colTwo} className="copyrightClass">
              <Box {...copyrightMenu} className="copyrightMenu">
                <Text
                  content="Copyright Elegant Aesthetic Beauty Clinic © 2023"
                  {...textStyle}
                />
              </Box>
              <Box {...copyright} className="copyrightText">
                <a
                  href="https://milkisnice.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text content="Site by MilkIsNice" {...textStyle} />
                </a>
              </Box>
            </Box>
            {/* End of footer List column */}
          </Box>
          <Box {...copyrightMenu} className="copyrightMenuUnder">
            <Text
              content="Registered Office: 38 Haven Road, Canford Cliffs, BH13 7LP. Company No. 11549000"
              {...textStyle}
            />
          </Box>
        </Fade>
      </Container>
    </FooterWrapper>
  )
}

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  copyrightMenu: PropTypes.object,
  copyright: PropTypes.object,
  LinksStyle: PropTypes.object,
}

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // Footer col one style
  colTwo: {
    mb: ["0px", 0],
    pl: [0],
    pt: ["32px"],
    pr: ["15px", "15px", 0],
    borderTop: "1.5px solid",
    borderColor: "#E0E0E0",
    flexBox: true,
    flexWrap: "wrap",
    width: ["100%"],
  },
  // Footer col two style
  colOne: {
    width: ["100%"],
    flexBox: true,
    flexWrap: ["wrap", "wrap", "wrap", "wrap", "nowrap"],
  },
  // Footer col default style
  col: {
    width: ["100%", "50%", "50%", "25%", "23%"],
    mr: ["30px", "40px"],
    mb: "30px",
  },
  // widget title default style
  titleStyle: {
    color: "#333333",
    fontSize: "15px",
    fontWeight: "700",
    fontFamily: "Mulish",
    letterSpacing: "0.96px",
    mb: "24px",
  },
  // widget title default style
  LinksStyle: {
    color: "#333333",
    fontSize: "15px",
    fontWeight: "700",
    fontFamily: "Mulish",
    letterSpacing: "0.96px",
    mb: "16px",
  },
  // widget text default style
  textStyle: {
    color: "#656565",
    fontSize: "14px",
    mb: "0px",
    mr: "0px",
    fontWeight: "100",
  },
  copyrightMenu: {
    flexBox: true,
    flexWrap: "wrap",
    mt: "3px",
    fontWeight: "500",
    justifyContent: "left",
    alignItems: "left",
    mb: ["15px", 0],
  },
  copyrightMenuUnder: {
    flexBox: true,
    flexWrap: "wrap",
    mt: "3px",
    fontWeight: "500",
    justifyContent: "left",
    alignItems: "left",
    textAlign: "left",
    mb: ["15px", 0],
    fontSize: "18px",
  },
  copyright: {
    ml: [0, 0, 0, "auto", "auto"],
    color: "#656565",
    fontSize: "12px",
    mt: "3px",
    fontWeight: "500",
    justifyContent: "center",
    alignItems: "center",
  },
}

export default Footer
