import "../../static/fonts/fonts.css"

import styled, { createGlobalStyle, keyframes } from "styled-components"

import { themeGet } from "@styled-system/theme-get"

const shake = keyframes`
0% {
  transform: translateX(-1px);
}
50% {
  transform: translateX(5px);
}
100% {
  transform: translateX(0);
}
`

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Mulish', sans-serif;
    background: #fbfbf9;
  }
  .reuseModalParentWrapper,
  .reuseModalOverlay {
    z-index: 99999;
    .reuseModalHolder{
      border: 0;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'bariol-regular', sans-serif;
    letter-spacing: 5px;
  }

  h3 {
    letter-spacing: 2px;
    margin-top:30px;
  }
  p {
    font-family: 'Mulish', sans-serif;
  }
  a {
    color: #297E7F;
  }
  section {
    position: relative;
  }
  .reusecore__button {
    font-weight: 500;
  }
  .priceextra{
  max-width: 900px;
  margin:0 auto;
  margin-bottom: 50px;
  padding: 20px;
}

  .priceextra p{
  text-align:center;
  font-size: 15px;
}

.info-text {
  max-width: 900px;
  margin:0 auto;
  padding: 32px 20px 0 20px;
}

 .info-text p{
  text-align:center;
  font-size: 13px;
  margin: 0px;
  line-height: 1.9;
  color: #886638;
}

.free-consultation {
	color:${themeGet("colors.secondaryBotton", "#886638")} !important;
}

  @media only screen and (max-width: 667px) {
    .container {
      width: 100%;
    }
  }
  @media only screen and (max-width: 667px) {
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media only screen and (width: 320px) {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  /* Modal default style */
  button.modalCloseBtn {
    color: ${themeGet("colors.white", "#ffffff")} !important;
    border-color: ${themeGet("colors.white", "#ffffff")} !important;

  }
  .reuseModalHolder {
    border: 0 !important;
    background-color: transparent !important;
    &.search-modal,
    &.video-modal {
      background-color: rgba(255, 255, 255, 0.96) !important;
      overflow-y: auto !important;
      .innerRndComponent {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        iframe {
          max-width: 90% !important;
          max-height: 90% !important;
          width: 100% !important;
          height: 100% !important;
          border-radius: 5px !important;
        }
      }
    }
    &.demo_switcher_modal {
      border: 0 !important;
      background-color: rgba(16, 30, 77, 0.8) !important;
      .innerRndComponent {
        border-radius: 8px !important;
      }
    }
    &.video-modal {
      background-color: transparent !important;
    }
    .innerRndComponent {
      padding-right: 0 !important;
    }
  }
  .reuseModalCloseBtn {
    cursor: pointer !important;
  }

  .reuseModalOverlay,
  .reuseModalParentWrapper{
    z-index: 99999!important;
  }

    .reuseModalHolder.login-modal{
      @media (min-width: 768px) {
        top: 0!important;
        left: 0!important;
        max-width: 100%!important;
        max-height: 100%!important;
      }
    }

    .reuseModalHolder.search-modal{
      top: 0!important;
      left: 0!important;
      max-width: 100%!important;
      max-height: 100%!important;
      width: 100%;
      height: 100%;
    }

    .reuseModalHolder.login-modal .innerRndComponent{
      overflow-y: auto;
    }

		.gift-voucher-mobile {
     display: none;
      @media only screen and (max-width: 568px) {
			display: block;
			font-size: 15px;
      color: ${themeGet("colors.secondaryBotton", "#886638")};
      font-weight: 600;
      text-decoration: underline;
      margin-right: 24px;
      transition: all 0.3s ease;
			margin-bottom: 16px;
      }
    }
`

export const ElegantWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .sticky-active {
    .navbar {
      padding: 20px 0 21px;
      background-color: rgba(3, 2, 51, 0.6);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      background-image: none;
      @media only screen and (max-width: 1366px) {
        padding: 15px 0 16px;
      }
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .mobile-menu {
        overflow: auto;
        top: 72px;
      }
      ul {
        li {
          a {
            color: ${themeGet("colors.white", "#fff")};
            font-size: 16px;
            font-weight: 600;
          }
          &.is-current {
            a {
              color: ${themeGet("colors.primary", "#03103b")};
            }
          }
        }
      }
      .reusecore__button {
        &.menubar {
          color: ${themeGet("colors.white", "#fff")};
        }
        &.text {
          color: ${themeGet("colors.secondary", "#000")};
          .btn-icon {
            svg {
              stroke: ${themeGet("colors.secondary", "#000")};
            }
          }
          @media only screen and (max-width: 991px) {
          }
        }
      }
    }
  }

  .learn__more-btn {
    display: inline-flex;
    align-items: center;
    /* font-family: "Open Sans", sans-serif; */
    color: ${themeGet("colors.white", "#060F1E")};
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    height: 50px;
    min-width: 151px;
    padding: 0 0 0 20px;
    position: relative;
    @media only screen and (max-width: 1360px) {
      font-size: 18px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 40px;
      width: 72px;
      background-color: ${themeGet("colors.primary")};
      transition: all 0.3s ease-out;
    }

    .btn_text {
      z-index: 1;
    }

    .hyphen {
      width: 20px;
      height: 2px;
      margin-right: 9px;
      background-color: ${themeGet("colors.white")};
      transform-origin: 50% 50%;
      z-index: 1;
    }

    &:hover {
      &::before {
        width: 100%;
      }

      .hyphen {
        animation: ${shake} 1s linear;
      }
    }
  }
`

export const ContentWrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
`

export const SectionHeader = styled.header`
  max-width: 352px;
  width: 100%;
  margin: 0 auto 58px;
  text-align: center;
  @media only screen and (max-width: 991px) {
    margin-bottom: 50px;
  }
  h5 {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px;
    letter-spacing: 1.5px;
    color: ${themeGet("colors.primary", "#2563FF")};
    text-transform: uppercase;
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: ${themeGet("colors.headingColor", "#0F2137")};
    margin: 0;
    letter-spacing: -1px;
    @media only screen and (max-width: 1366px) {
      font-size: 28px;
      letter-spacing: -0.7px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 38px;
      letter-spacing: -0.5px;
    }
  }
`

export default GlobalStyle
