const colors = {
  transparent: "transparent",
  primaryBotton: "#297E7F",
  secondaryBotton: "#886638",
  secondaryColor: "#297E7F",
  textColor: "#333333",
  backgroundColor: "#F5F5F5",
  secondaryTextColor: "#297E7F",
  labelColor: "#767676",
  shadow: "rgba(38, 78, 118, 0.1)",
}

export default colors
