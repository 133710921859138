import React from "react"
import { Link } from "gatsby"
import { SearchWrapper } from "./searchPreview.style"

const SearchPreview = ({ hit }) => {
  return (
    <>
      {hit.treatmentCategory === "aesthetics" ||
      hit.treatmentCategory === "health" ||
      hit.treatmentCategory === "slim" ? (
        <SearchWrapper>
          <div>
            <Link to={`/${hit.treatmentCategory}/${hit.slug}`}>
              {hit.treatmentTitle}
            </Link>
          </div>
        </SearchWrapper>
      ) : (
        <SearchWrapper>
          <div>
            <Link to={`/blog/${hit.blogPostSlug}`}>{hit.blogPostTitle}</Link>
          </div>
        </SearchWrapper>
      )}
    </>
  )
}

export default SearchPreview
