import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useContext, useState } from "react"

import { DrawerContext } from "../../contexts/DrawerContext"
import { Icon } from "react-icons-kit"
import { MobileSubMenuMenuWrapper } from "./scrollSpyMenu.style"
import PropTypes from "prop-types"
import Scrollspy from "react-scrollspy"
import { chevronDown } from "react-icons-kit/fa/chevronDown"
import { chevronRight } from "react-icons-kit/fa/chevronRight"

const ScrollSpyMenu = ({
  className,
  menuItems,
  drawerClose,
  mobileMenu,
  ...props
}) => {
  const [iconOpen, setIconOpen] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(null)

  const data = useStaticQuery(graphql`
    query SubmenuItems {
      aestheticsSubMenu: allContentfulTreatments(
        filter: { treatmentCategory: { eq: "aesthetics" } }
        sort: { fields: treatmentTitle, order: ASC }
      ) {
        edges {
          node {
            treatmentTitle
            slug
          }
        }
      }
      skinSubMenu: allContentfulTreatments(
        filter: { treatmentCategory: { eq: "skin" } }
        sort: { fields: treatmentTitle, order: ASC }
      ) {
        edges {
          node {
            treatmentTitle
            slug
          }
        }
      }
      healthSubMenu: allContentfulTreatments(
        filter: { treatmentCategory: { eq: "health" } }
        sort: { fields: treatmentTitle, order: ASC }
      ) {
        edges {
          node {
            treatmentTitle
            slug
          }
        }
      }
      slimSubMenu: allContentfulTreatments(
        filter: { treatmentCategory: { eq: "slim" } }
        sort: { fields: treatmentTitle, order: ASC }
      ) {
        edges {
          node {
            treatmentTitle
            slug
          }
        }
      }
      conditionsSubMenu: allContentfulConditionsSkinCondition(
        sort: { fields: title, order: ASC }
      ) {
        edges {
          node {
            slug
            title
          }
        }
      }
      travelSubMenu: allContentfulTreatments(
        filter: { treatmentCategory: { eq: "travel" } }
        sort: { fields: treatmentTitle, order: ASC }
      ) {
        edges {
          node {
            treatmentTitle
            slug
          }
        }
      }
      aboutSubMenu: allContentfulAboutPages(
        sort: { fields: menuTitle, order: ASC }
      ) {
        edges {
          node {
            category
            slug
            menuTitle
          }
        }
      }
    }
  `)

  const { dispatch } = useContext(DrawerContext)
  // empty array for scrollspy items
  const scrollItems = []

  // convert menu path to scrollspy items
  menuItems.forEach(item => {
    scrollItems.push(item.path.slice(1))
  })

  // Add all classs to an array
  const addAllClasses = ["scrollspy__menu"]

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  // Close drawer when click on menu item
  const toggleDrawer = () => {
    dispatch({
      type: "TOGGLE",
    })
  }

  const handleDropdown = label => {
    setOpenDropdown(label)

    if (label === openDropdown) {
      setOpenDropdown(null)
    }
  }

  return (
    <Scrollspy
      items={scrollItems}
      className={addAllClasses.join(" ")}
      drawerClose={drawerClose}
      mobileMenu={mobileMenu}
      {...props}
    >
      <Link to="/about/gift-voucher" className="gift-voucher-mobile">
        Buy A Gift Voucher
      </Link>
      {menuItems.map((menu, index) => (
        <li key={`menu-item-${index}`}>
          {menu.staticLink ? (
            <Link key={index} to={menu.path}>
              {menu.label}
            </Link>
          ) : (
            // <a href={menu.path}>{menu.label}</a>
            <>
              {mobileMenu ? (
                <MobileSubMenuMenuWrapper iconOpen={iconOpen}>
                  <div className="mobile-submenu-content">
                    <Link to={`${menu.path}`}>{menu.label}</Link>
                    {menu.label === "Gallery" ||
                    menu.label === "Products" ||
                    menu.label === "Beauty" ? null : (
                      <div
                        className="icon"
                        onClick={() => handleDropdown(menu.label)}
                      >
                        {menu.label === openDropdown ? (
                          <div className="iconClose">
                            <Icon icon={chevronDown} />
                          </div>
                        ) : (
                          <div className="iconOpen">
                            <Icon icon={chevronRight} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="mobile-submenu">
                    {openDropdown === "Aesthetics" &&
                    menu.label === "Aesthetics" ? (
                      <>
                        <a
                          className="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>
                        <Link href="/treatment-prices">
                          <a className="free-consultation">
                            Elegant Aesthetic Prices
                          </a>
                        </Link>
                        {data.aestheticsSubMenu.edges.map(({ node }, index) => {
                          return (
                            <Link key={index} to={`/aesthetics/${node.slug}/`}>
                              {node.treatmentTitle}
                            </Link>
                          )
                        })}
                      </>
                    ) : openDropdown === "Skin" && menu.label === "Skin" ? (
                      <>
                        <a
                          className="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>
                        <Link href="/treatment-prices">
                          <a className="free-consultation">
                            Skin & Beauty Prices
                          </a>
                        </Link>
                        {data.skinSubMenu.edges.map(({ node }, index) => {
                          return (
                            <>
                              {index === 1 ? (
                                <>
                                  <Link to="/beauty-treatments-bournemouth-and-poole/">
                                    Beauty Treatments
                                  </Link>
                                  <Link to={`/skin/${node.slug}/`}>
                                    {node.treatmentTitle}
                                  </Link>
                                </>
                              ) : (
                                <Link to={`/skin/${node.slug}/`}>
                                  {node.treatmentTitle}
                                </Link>
                              )}
                            </>
                          )
                        })}
                      </>
                    ) : openDropdown === "Health" && menu.label === "Health" ? (
                      <>
                        <a
                          classname="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>
                        <Link href="/treatment-prices">
                          <a className="free-consultation">
                            Elegant Health Prices
                          </a>
                        </Link>
                        {data.healthSubMenu.edges.map(({ node }, index) => {
                          return (
                            <Link key={index} to={`/health/${node.slug}/`}>
                              {node.treatmentTitle}
                            </Link>
                          )
                        })}
                      </>
                    ) : openDropdown === "Slim" && menu.label === "Slim" ? (
                      <>
                        <a
                          classname="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>
                        <Link href="/treatment-prices">
                          <a className="free-consultation">
                            Elegant Slim Prices
                          </a>
                        </Link>
                        {data.slimSubMenu.edges.map(({ node }, index) => {
                          return (
                            <Link key={index} to={`/slim/${node.slug}/`}>
                              {node.treatmentTitle}
                            </Link>
                          )
                        })}
                      </>
                    ) : openDropdown === "Travel" && menu.label === "Travel" ? (
                      <>
                        <a
                          classname="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>

                        {data.aboutSubMenu.edges.map(({ node }, index) => {
                          return (
                            <>
                              {node.category === "travel" ? (
                                <Link key={index} to={`/travel/${node.slug}/`}>
                                  {node.menuTitle}
                                </Link>
                              ) : null}
                            </>
                          )
                        })}
                        {data.travelSubMenu.edges.map(({ node }, index) => {
                          return (
                            <Link key={index} to={`/travel/${node.slug}/`}>
                              {node.treatmentTitle}
                            </Link>
                          )
                        })}
                      </>
                    ) : openDropdown === "About" && menu.label === "About" ? (
                      <>
                        <Link to={`/blog/`}>Blog</Link>
                        {/* <Link to={`/about/finance/`}>Finance</Link> */}
                        <Link to={`/gallery/`}>Gallery</Link>
                        <Link to={`/about/meet-the-team/`}>Meet the team</Link>
                        <Link to={`/treatment-offers/`}>Offers</Link>
                        {/* <Link to={`/treatment-prices/`}>Prices</Link> */}

                        {data.aboutSubMenu.edges.map(({ node }, index) => {
                          return (
                            <>
                              {node.category === "about" ? (
                                <>
                                  {node.category === "about" ? (
                                    node.slug !== "gift-voucher" ? (
                                      <Link
                                        key={index}
                                        to={`/about/${node.slug}/`}
                                      >
                                        {node.menuTitle}
                                      </Link>
                                    ) : null
                                  ) : null}
                                </>
                              ) : null}
                            </>
                          )
                        })}
                      </>
                    ) : openDropdown === "Contact" &&
                      menu.label === "Contact" ? (
                      <>
                        <Link to={`/contact/`}>Contact Us</Link>
                        <Link to={`/feedback-form/`}>Feedback Form</Link>
                      </>
                    ) : null}
                  </div>
                </MobileSubMenuMenuWrapper>
              ) : (
                <>
                  <Link
                    activeStyle={{
                      borderBottom: "3px solid #333333",
                      padding: "24px 0",
                    }}
                    to={`${menu.path}`}
                  >
                    {menu.label}
                  </Link>
                  {menu.label === "Aesthetics" ? (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        <a
                          className="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>
                        <div style={{ marginTop: "16px" }}>
                          <Link href="/treatment-prices">
                            <a className="free-consultation">
                              Elegant Aesthetic Prices
                            </a>
                          </Link>
                        </div>
                        {data.aestheticsSubMenu.edges.map(({ node }, index) => {
                          return (
                            <Link key={index} to={`/aesthetics/${node.slug}/`}>
                              {node.treatmentTitle}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  ) : menu.label === "Skin" ? (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        <a
                          className="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>
                        <div style={{ marginTop: "16px" }}>
                          <Link href="/treatment-prices">
                            <a className="free-consultation">
                              Skin & Beauty Prices
                            </a>
                          </Link>
                        </div>
                        {data.skinSubMenu.edges.map(({ node }, index) => {
                          return (
                            <>
                              {index === 1 ? (
                                <>
                                  <Link to="/beauty-treatments-bournemouth-and-poole/">
                                    Beauty Treatments
                                  </Link>
                                  <Link to={`/skin/${node.slug}/`}>
                                    {node.treatmentTitle}
                                  </Link>
                                </>
                              ) : (
                                <Link key={index} to={`/skin/${node.slug}/`}>
                                  {node.treatmentTitle}
                                </Link>
                              )}
                            </>
                          )
                        })}
                      </div>
                    </div>
                  ) : menu.label === "Health" ? (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        <a
                          className="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>
                        <div style={{ marginTop: "16px" }}>
                          <Link href="/treatment-prices">
                            <a className="free-consultation">
                              Elegant Health Prices
                            </a>
                          </Link>
                        </div>
                        {data.healthSubMenu.edges.map(({ node }, index) => {
                          return (
                            <Link key={index} to={`/health/${node.slug}/`}>
                              {node.treatmentTitle}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  ) : menu.label === "Slim" ? (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        <a
                          className="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>
                        <div style={{ marginTop: "16px" }}>
                          <Link href="/treatment-prices">
                            <a className="free-consultation">
                              Elegant Slim Prices
                            </a>
                          </Link>
                        </div>
                        {data.slimSubMenu.edges.map(({ node }, index) => {
                          return (
                            <Link key={index} to={`/slim/${node.slug}/`}>
                              {node.treatmentTitle}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  ) : menu.label === "Conditions" ? (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        {data.conditionsSubMenu.edges.map(({ node }, index) => {
                          return (
                            <Link key={index} to={`/conditions/${node.slug}/`}>
                              {node.title}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  ) : menu.label === "Travel" ? (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        <a
                          style={{ color: "#886638" }}
                          classname="free-consultation"
                          href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Free Consultation
                        </a>
                        {data.aboutSubMenu.edges.map(({ node }, index) => {
                          return (
                            <>
                              {node.category === "travel" ? (
                                <Link key={index} to={`/travel/${node.slug}/`}>
                                  {node.menuTitle}
                                </Link>
                              ) : null}
                            </>
                          )
                        })}
                        {data.travelSubMenu.edges.map(({ node }, index) => {
                          return (
                            <Link key={index} to={`/travel/${node.slug}/`}>
                              {node.treatmentTitle}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  ) : menu.label === "About" ? (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        <Link to={`/blog/`}>Blog</Link>
                        {/* <Link to={`/about/finance/`}>Finance</Link> */}
                        <Link to={`/gallery/`}>Gallery</Link>
                        <Link to={`/about/meet-the-team/`}>Meet the team</Link>
                        <Link to={`/treatment-offers/`}>Offers</Link>
                        {/* <Link to={`/treatment-prices/`}>Prices</Link> */}

                        {data.aboutSubMenu.edges.map(({ node }, index) => {
                          return (
                            <>
                              {node.category === "about" ? (
                                node.slug !== "gift-voucher" ? (
                                  <Link key={index} to={`/about/${node.slug}/`}>
                                    {node.menuTitle}
                                  </Link>
                                ) : null
                              ) : null}
                            </>
                          )
                        })}
                        {/* <Link to={`/about/client-testimonials/`}>
                          Client Testimonials
                        </Link> */}
                      </div>
                    </div>
                  ) : menu.label === "Contact" ? (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        <Link to={`/contact/`}>Contact Us</Link>
                        <Link to={`/feedback-form/`}>Feedback Form</Link>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </>
          )}
        </li>
      ))}
    </Scrollspy>
  )
}

ScrollSpyMenu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,

  /** Class name that apply to the navigation elements that have been scrolled past [optional]. */
  scrolledPastClassName: PropTypes.string,

  /** HTML tag for Scrollspy component if you want to use other than <ul/> [optional]. */
  componentTag: PropTypes.string,

  /** Style attribute to be passed to the generated <ul/> element [optional]. */
  style: PropTypes.object,

  /** Offset value that adjusts to determine the elements are in the viewport [optional]. */
  offset: PropTypes.number,

  /** Name of the element of scrollable container that can be used with querySelector [optional]. */
  rootEl: PropTypes.string,

  /**
   * Function to be executed when the active item has been updated [optional].
   */
  onUpdate: PropTypes.func,
}

ScrollSpyMenu.defaultProps = {
  componentTag: "ul",
  currentClassName: "is-current",
}

export default ScrollSpyMenu
