import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const FooterWrapper = styled.section`
  .socialInfos {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;
    @media (max-width: 580px) {
      flex-direction: column;
    }
    .social {
      display: flex;
      margin-right: 32px;
      align-items: center;

      @media (max-width: 490px) {
        margin-bottom: 16px;
      }
      h2 {
        font-size: 14px;
        margin-bottom: 0px;
        margin-right: 16px;
      }
    }

    .policies {
      display: flex;
      align-items: center;
      @media (max-width: 490px) {
        flex-direction: column;
        align-items: flex-start;
      }
      li {
        display: flex;
        @media (max-width: 490px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      p {
        font-size: 14px;
        margin-right: 10px;
      }
      h2 {
        font-size: 14px;
        margin-bottom: 0px;
        margin-right: 16px;
        @media (max-width: 490px) {
          margin-bottom: 8px;
        }
      }
    }

    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-top: -40px;
      flex-wrap: wrap;
      gap: 20px;
      @media (max-width: 1100px) {
        margin-left: 0px;
        margin-top: 40px;
      }
      @media (max-width: 580px) {
        margin-left: 0px;
        margin-top: 30px;
        justify-content: flex-start;
        gap: 10px;
      }
    }

    .save-face {
      img {
        width: 50px;
      }
    }

    .hydrafacial {
      img {
        width: 80px;
      }
    }

    .profhilo {
      img {
        width: 80px;
        margin-right: 15px;
      }
    }
    .seventyhyal {
      img {
        width: 80px;
      }
    }
    .teosyal {
      img {
        width: 80px;
      }
    }
    .zoSkin {
      img {
        width: 80px;
      }
    }

    .cqc {
      img {
        width: 70px;
      }
    }

    .finance {
      img {
        width: 100px;
      }
    }

    @media (max-width: 580px) {
      margin-left: 0px;
      margin-bottom: 32px;
    }
  }

  .ListIcon {
    margin-right: 16px;
    margin-top: 5px;
  }

  padding: 72px 0 32px;
  background-color: #f5f5f5;
  @media (max-width: 480px) {
    padding: 40px 0 32px;
  }
  .copyrightClass {
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 480px) {
      align-items: start;
    }
    .copyrightMenu {
      @media (max-width: 1024px) {
        margin-bottom: 10px;
        justify-content: left;
        align-items: left;
        margin-top: 0px;
        margin-left: 0;
      }
      @media (max-width: 767px) {
        justify-content: left;
        align-items: left;
        margin-left: 0;
        margin-bottom: 10px;
      }
      @media (max-width: 480px) {
        justify-content: left;
        align-items: left;
        margin-left: 0;
        margin-bottom: 0px;
      }
    }
    .copyrightText {
      @media (max-width: 1100px) {
        margin-left: 0;
      }
    }
  }
`

const List = styled.ul``

const ListItem = styled.li`
  img {
    &:first-child {
      /* align-self: end; */
    }
  }
  display: flex;
  margin-bottom: 16px;

  p {
    color: #656565;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  a {
    color: #656565;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  a p {
    color: #656565;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 8px;
  }
`
const LinksListItem = styled.li`
  p {
    color: #656565;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 16px;
  }

  a p {
    color: #656565;
    font-size: 14px;
    line-height: 26px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`

export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 0 4px -5px;

  li {
    margin-right: 14px;
    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet("colors.lightText", "#717171")};
      position: relative;
      i {
        position: relative;
        z-index: 1;

        svg {
          width: 14px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet("colors.secondaryColor", "#297E7F")};
      }
    }
  }
`

export { List, ListItem, LinksListItem }

export default FooterWrapper
