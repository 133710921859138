import { StaticQuery, graphql } from "gatsby"

import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import React from "react"

function SEO({ description, lang, meta, title, shortDesc, image, url }) {
  const schemaOrgWebPage = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://www.elegantclinic.co.uk/",
    logo: "",
    name: "Elegant Aesthetic Beauty Clinic",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+44 (0)7498 779511 (Text and WhatsApp)",
      contactType: "Customer service",
    },
    address: {
      "@type": "PostalAddress",
      addressLocality: "Poole",
      postalCode: "BH14 0HU",
      streetAddress: "38 Haven Road, Canford Cliffs BH13 7LP",
      addressRegion: "Dorset",
      addressCountry: {
        "@type": "Country",
        name: "United Kingdom",
      },
    },
  }
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${shortDesc}`}
          >
            <meta
              name="facebook-domain-verification"
              content="4p7g291n1tsf4cxmyxc29g9lzy8gsy"
            />
            <meta name="format-detection" content="telephone=no" />
            <meta name="description" content={description} />
            <meta name="image" content={data.site.siteMetadata.image} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${title} | ${shortDesc}`} />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content={
                image ||
                `${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`
              }
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgWebPage)}
            </script>
            <script
              type="text/javascript"
              id="hs-script-loader"
              async
              defer
              src="//js-eu1.hs-scripts.com/25716051.js"
            ></script>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  shortDesc: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        image
      }
    }
  }
`
