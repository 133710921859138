import Sticky from "react-stickynode"
import React, { Fragment } from "react"
import { ThemeProvider } from "styled-components"

import Footer from "../../containers/Footer"
import Navbar from "../../containers/Navbar"
import TopBar from "../../containers/TopBar"
import GlobalStyle, { ElegantWrapper } from "../../containers/elegant.style.js"
import { ResetCSS } from "../../common/assets/css/style"
import { elegantTheme } from "../../theme/theme"

const Layout = ({ home, children }) => {
  return (
    <ThemeProvider theme={elegantTheme}>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        {/* End of elegant head section */}
        {/* Start elegant wrapper section */}
        <ElegantWrapper>
          {/* <TopBar /> */}
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar home={home} />
          </Sticky>
          {children}
          <Footer />
        </ElegantWrapper>
      </Fragment>
    </ThemeProvider>
  )
}

export default Layout
