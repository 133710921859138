import colors from "./colors"
export const elegantTheme = {
  breakpoints: ["480px", "768px", "990px", "1220px", "1440"],
  space: [0, 8, 16, 32, 40, 56, 80, 120, 160],
  fontSizes: [8, 12, 14, 16, 18, 24, 32, 40, 56, 64, 80],
  fontWeights: [300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: "normal",
    tracked: "0.1em",
    tight: "-0.05em",
    mega: "0.25em",
  },
  fonts: {
    Mulish: '"Mulish", sans-serif',
  },
  borders: [0, "1px solid", "2px solid", "4px solid"],
  radius: [0, 3, 5, 10, 15, 20, 25, 50, 60, "50%"],
  colors,
  colorStyles: {
    primary: {
      color: colors.primary,
      borderColor: colors.primary,
      "&:hover": {
        boxShadow: "colors.primary 0px 12px 24px -10px",
      },
    },
    secondary: {
      color: colors.secondary,
      borderColor: colors.secondary,
      "&:hover": {
        color: colors.secondaryHover,
        borderColor: colors.secondaryHover,
      },
    },
    primaryWithBg: {
      color: colors.white,
      backgroundColor: colors.primary,
      borderColor: colors.primary,
    },
    secondaryWithBg: {
      color: colors.white,
      backgroundColor: colors.secondary,
      borderColor: colors.secondary,
      "&:hover": {
        backgroundColor: colors.secondaryHover,
        borderColor: colors.secondaryHover,
      },
    },
  },
  buttonStyles: {
    textButton: {
      border: 0,
      color: colors.primary,
      padding: 0,
      height: "auto",
      backgroundColor: `${colors.transparent}`,
    },
    outlined: {
      borderWidth: "1px",
      borderStyle: "solid",
      backgroundColor: colors.transparent,
    },
  },
}
