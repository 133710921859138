import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

import searchIcon from "../../common/assets/image/elegant/search.svg"

export const Error = styled.div`
  display: ${props => (props.searchToggle ? "none" : "block")};
  position: absolute;
  top: 56px;
  background: white;
  right: 48px;
  z-index: 10000;
  min-width: 348px;
  box-shadow: 0 0 100px rgba(175, 182, 194, 0.3);
  padding: 12px 16px;
  max-height: 415px;
  overflow: auto;
  @media only screen and (max-width: 1366px) {
    min-width: 320px;
  }
`

const TopBarWrapper = styled.div`
  background-color: ${themeGet("colors.primary")};
  color: ${themeGet("colors.textColor", "#333333")};
  padding: 8px 0;
  font-size: 14px;
  display: ${props => (props.isHide ? "none" : "block")};
  @media only screen and (max-width: 1200px) {
    display: none;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  .ais-Hits {
    display: ${props =>
      props.searchReasults && props.emptySearch !== 0 ? "block" : "none"};
    position: absolute;
    top: 56px;
    background: white;
    right: 48px;
    z-index: 10000;
    min-width: 348px;
    box-shadow: 0 0 100px rgba(175, 182, 194, 0.3);
    padding: 12px 16px;
    max-height: 415px;
    overflow: auto;

    @media only screen and (max-width: 1366px) {
      min-width: 320px;
    }

    div {
      padding: 3px 0;
      cursor: pointer;
    }
    div a {
      color: ${themeGet("colors.textColor", "#333333")};
    }
    div:hover a {
      color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
    }
  }

  .ais-SearchBox-resetIcon {
    display: none;
  }

  button.ais-SearchBox-reset {
    display: none;
  }
  button.ais-SearchBox-submit {
    display: none;
  }
  .ais-SearchBox {
    margin-top: -44px;
  }
  > div.container {
    padding: 0 80px;
    @media only screen and (max-width: 1600px) {
      padding: 0 40px;
    }
    @media only screen and (max-width: 1366px) {
      padding: 0 20px;
    }
  }
`

export const TopbarInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
  .active {
    .menu {
      opacity: 0;
      visibility: hidden;
    }
    .search {
      form {
        display: flex;
        flex-direction: column;
        opacity: 1;
        visibility: visible;
      }
      input {
        width: 320px;
        @media only screen and (min-width: 1367px) {
          width: 350px;
        }
        @media only screen and (max-width: 480px) {
          width: calc(100vw - 122px);
        }
      }
    }
  }
`

export const Search = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  form {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 100%;
  }
  .text {
    color: ${themeGet("colors.textColor", "#333333")};
    &:hover {
      background-color: #fbfbf9;
    }
  }
  input {
    width: 0px;
    height: 46px;
    padding: 0 15px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 15px;
    color: ${themeGet("colors.menu", "0D233E")};
    background-color: ${themeGet("colors.light", "#FAFBFF")};
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
    background-size: 16px;
    z-index: 1;
    /* transition: all 0.3s ease 0.1s; */
    &::placeholder {
      color: ${themeGet("colors.textColor", "rgba(52, 61, 72, 0.8)")};
    }
    &:focus {
      border-color: ${themeGet("colors.secondaryColor", "#297E7F")};
    }
  }
`
export const ContactDetails = styled.div`
  display: flex;
  img {
    margin-right: 16px;
  }
`

export default TopBarWrapper
