import React, { useState, useRef } from "react"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectStateResults,
} from "react-instantsearch-dom"
import { x } from "react-icons-kit/feather/x"
import { search } from "react-icons-kit/feather/search"
import { Icon } from "react-icons-kit"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import Container from "../../components/UI/Container"
import Button from "../../components/Button"
import Image from "../../components/Image"
import phoneIcon from "../../common/assets/image/elegant/footer/phone.svg"
import SearchPreview from "../../containers/SearchPreview"
import TopBarWrapper, {
  TopbarInner,
  Search,
  ContactDetails,
  Error,
} from "./topbar.style"
import { divide } from "lodash"

const Results = connectStateResults(
  ({ searchState: state, searchResults: res }) => {
    return res && res.nbHits > 0 ? (
      <Hits hitComponent={SearchPreview} />
    ) : (
      <Error>No result found...</Error>
    )
  }
)

const TopBar = () => {
  const [closeTopBar] = useState(false)
  const [state, setState] = useState({
    search: "",
    searchToggle: false,
    mobileMenu: false,
  })

  const handleOnChange = event => {
    setState({
      ...state,
      search: event.target.value,
    })
  }

  const searchRef = useRef(null)
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  )

  const toggleHandler = type => {
    if (type === "search") {
      setState({
        ...state,
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      })
    }

    if (type === "menu") {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      })
    }
  }

  const handleSearchForm = event => {
    event.preventDefault()

    if (state.search !== "") {
      console.log("search data: ", state.search)

      setState({
        ...state,
        search: "",
      })
    } else {
      console.log("Please fill this field.")
    }
  }

  let firstLoad = true

  const algoliaClient = algoliasearch(
    "4O8BJF5UVB",
    "5ad5479624a9eebbdbcf21f71984e916"
  )

  const searchClient = {
    search(requests) {
      console.log("search -> requests", requests)
      if (firstLoad === true) {
        firstLoad = false
        return
      }
      return algoliaClient.search(requests)
    },
  }

  return (
    <TopBarWrapper
      isHide={closeTopBar && closeTopBar}
      searchReasults={state.searchToggle}
      emptySearch={state.search.length}
    >
      <Container mobileGutter fullWidth>
        <TopbarInner>
          <ContactDetails>
            <Image src={phoneIcon} alt="" className="ListIcon" />
            <p className="ListItem">07817 594 092</p>
          </ContactDetails>
          {/* <div className={state.searchToggle ? "active" : ""}>
            <Search className="search" ref={searchRef}>
              <InstantSearch
                searchClient={searchClient}
                indexName="ElegantBeauty"
              >
                <SearchBox
                  showReset={true}
                  searchAsYouType={true}
                  onChange={handleOnChange}
                />
                {state.search.length !== 0 && <Results />}
              </InstantSearch>
              <Button
                ariaLabel="search"
                className="text"
                variant="textButton"
                icon={<Icon icon={state.searchToggle ? x : search} />}
                onClick={() => toggleHandler("search")}
              />
            </Search>
          </div> */}
        </TopbarInner>
      </Container>
    </TopBarWrapper>
  )
}

export default TopBar
